import React from 'react';
import { shape, string, oneOf } from 'prop-types';
import { Box } from '@mui/material';

import Markdown from '@/components/Markdown';
import themes from '../../theme/helpers/bannerThemes';

const Banner = ({ text, theme }) => {
  const bannerTheme = themes[theme];
  return (
    <Box p={1} width="100%" textAlign="center" bgcolor={bannerTheme.backgroundColor}>
      <Markdown source={text} theme={bannerTheme} />
    </Box>
  );
};

Banner.propTypes = {
  text: shape({
    compiledSource: string.isRequired,
  }).isRequired,
  theme: oneOf(['ghost', 'smoke', 'periwinkle_dark', 'periwinkle_main', 'flamingo', 'green']).isRequired,
};
export default Banner;
