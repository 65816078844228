export default {
  ghost: {
    backgroundColor: 'neutral.lighter',
    bodyColor: 'text.primary',
  },
  smoke: {
    backgroundColor: 'neutral.light',
    bodyColor: 'text.primary',
  },
  flamingo: {
    backgroundColor: 'accent.main',
    bodyColor: 'neutral.lightest',
  },
  periwinkle_dark: {
    backgroundColor: 'text.primary',
    bodyColor: 'neutral.lightest',
  },
  periwinkle_main: {
    backgorundColor: 'secondary.main',
    bodyColor: 'neutral.lightest',
  },
  green: {
    backgroundColor: 'primary.main',
    bodyColor: 'neutral.lightest',
  },
};
